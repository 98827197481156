import Cookies from 'universal-cookie';

export const hasCookie = () => {
  const cookies = new Cookies();

  if (cookies.get('studio') != undefined) {
    return true;
  }

  return false;

};