import React, { Component } from 'react';

class FilterSearchWork extends Component {
  render() {
    const projectNbrText = this.props.allWork.length > 2 ? "projects" : "project";

    return (
      <nav className="uk-navbar-container" data-uk-navbar>
        <div className="uk-navbar-left">
          <div className="uk-navbar-item search-field">
            <form className="uk-search uk-search-navbar">
              <span data-uk-search-icon></span>
              <input className="uk-search-input search js-search uk-input uk-form-width-large uk-margin-small" type="search" placeholder="Search a project..."/>
            </form>
          </div>
        </div>
        <div className="uk-navbar-right">
          <span className="project-nbr"><span className="js-project-nbr">{this.props.allWork.length}</span> <span className="js-project-text">{projectNbrText} visible</span></span>
          <button className="uk-button uk-button-default uk-margin-right work__canvas-btn" type="button" data-uk-toggle="target: #offcanvas-push">More filters</button>
          <button className="js-reset uk-button uk-button-primary uk-margin-right" type="button">Reset</button>
        </div>
      </nav>
    );
  }
}

export default FilterSearchWork;
