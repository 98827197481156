import React, { Component } from 'react';

class FilterRadio extends Component {
  render() {

    const filterName = this.props.filterName;
    const filterData = this.props.filterData;
    const filterGroup = this.props.filterGroup

    return (
      <div className="filters__list">
        <h3>{filterName}</h3>
        <div className="js-filter-group uk-margin uk-grid-small uk-child-width-auto uk-grid filter-group" data-filter-group={`${filterGroup}`}>
          {filterData.edges.map((node, index) => {
            if (index < 5) {
              return (
                <label key={node.node.id}>
                  <input className="filter uk-radio" type="radio" name={filterGroup} value={`.${node.node.slug}`} data-filter={`.${node.node.slug}`} />
                  <span dangerouslySetInnerHTML={{ __html: node.node.name }} />
                </label>
                )
            } else {
              return (
                <label key={node.node.id} className="hide-filter">
                  <input className="filter uk-radio" type="radio" name={filterGroup} value={`.${node.node.slug}`} data-filter={`.${node.node.slug}`} />
                  <span dangerouslySetInnerHTML={{ __html: node.node.name }} />
                </label>
                )
            }
          })}
        </div>
        <div className="show-more-btn">
          <span></span>
        </div>
      </div>
    );
  }
}

export default FilterRadio;