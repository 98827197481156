import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import FilterSearchWork from '../components/filters/workSearch'
import FilterSelect from '../components/filters/workSelectFilter'
import FilterCheckbox from '../components/filters/workCheckboxFilter'
import FilterRadio from '../components/filters/workRadioFilter'
import GlobalBanner from '../components/globalBanner'
import { hasCookie } from '../services/cookies';
import _ from 'underscore';

class Work extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "microsite",
      filteredData: []
    }
  }

  // - - - Add filter input search - - -
  // filterSearch(data, event) {
  //   var updateList = data;

  //   updateList = updateList.filter((item) => {
  //     console.log(item.node.title)
  //     return item.node.title.toLowerCase().search(
  //       event.target.value.toLowerCase()) !== -1;
  //     });

  //   this.setState({
  //     filteredData: updateList
  //   })
  // }

  render() {
    const data = this.props.data;

    const workPageAcf = data.wpPage.titleSubtitleACF;
    let dataToDisplay = data.allWpProject.edges.reverse(); // Order by date

    // Retrieve each work
    let workList = dataToDisplay.map(({node, index}) => {
      let technos = [];
      if (node.projectTechnologies !== null) {
        node.projectTechnologies.nodes.map((techno) => {
          technos.push(techno.slug)
          return technos;
        });
      }
      return (
        // End .item.visible.uk-margin-top.uk-margin-bottom
        <div key={index} className={`item visible uk-margin-bottom uk-margin-top ${(node.projectTypes !== null && node.projectTypes.nodes[0] != undefined) && "filter-" + node.projectTypes.nodes[0].slug} ${(node.sectors !== null && node.sectors.nodes[0] != undefined) && node.sectors.nodes[0].slug} ${technos.join(" ")} ${(node.projectYears !== null && node.projectYears.nodes[0] != undefined) && "filter-"+node.projectYears.nodes[0].slug}`}>
          <div className="uk-card uk-card uk-card-default">
            <div className="uk-card-header">
              <div className="uk-flex-middle uk-text-center uk-flex-center">
                  <div className="uk-text-center">
                      <Link to={`/work/project/${node.slug}/`}>
                      <h3 className="" dangerouslySetInnerHTML={{ __html: node.title }} />
                      </Link>
                  </div>
              </div>
            </div>
            <div className="uk-card-media-top">
              <Link to={`/work/project/${node.slug}/`}>
                {node.featuredImage !== null &&
                  node.featuredImage.node !== null ? (
                    node.featuredImage.node.localFile !== null ? (
                      node.featuredImage.node.localFile.childImageSharp !== null &&
                      <GatsbyImage image={getImage(node.featuredImage.node.localFile)} />
                    ) : (
                      node.featuredImage !== null &&
                        <img className="imgPath" src={node.featuredImage.node.sourceUrl} alt={node.featuredImage.node.altText} />
                    )
                  ) : (
                    <img src={'/img/default-project-img.jpg'} alt="Edelman Default Project Image" />
                  )
                }
              </Link>
            </div>
            <div className="uk-card-footer">
              <div className="item__cats">
                {(node.projectTypes !== null && node.projectTypes.nodes[0] != undefined) &&
                  <span className="uk-label uk-label-success uk-text-meta	" dangerouslySetInnerHTML={{ __html: node.projectTypes.nodes[0].name }} />
                }
                {(node.sectors !== null && node.sectors.nodes[0] != undefined) &&
                  <span className="uk-label uk-label-success uk-text-meta	" dangerouslySetInnerHTML={{ __html: node.sectors.nodes[0].name }} />
                }
              </div>
            </div>
          </div> {/* End .uk-card */}
        </div>
      );
    })

    if (hasCookie() == true) {
      return (
        <Layout>
          <div className="work-template">
            <div className="work__content uk-offcanvas-content uk-background-muted">

              <GlobalBanner pageTitle={workPageAcf.titlePage}
                            pageSubTitle={workPageAcf.subtitlePage}
                            bgSharpImg={data.wpPage.featuredImage.node.localFile !== null && data.wpPage.featuredImage.node.localFile.childImageSharp !== null && data.wpPage.featuredImage.node.localFile}
                            bgImg={data.wpPage.featuredImage.node.sourceUrl}
              />

              <div className="ctn-main uk-padding uk-background-default push-up-small">
                <div id="loading" className="centered uk-margin-large">Loading... <span className="uk-margin-small-right" data-uk-spinner="ratio: 1"></span></div>
                <div id="offcanvas-push" data-uk-offcanvas="mode: push; flup: true">
                  <div className="uk-offcanvas-bar">

                    <button className="uk-offcanvas-close" type="button" data-uk-close></button>

                    <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                      <div className="uk-width-expand">

                        <div className="filters">
                          <div className="uk-margin">
                            <FilterSelect
                              filterName="Project Type"
                              filterData={data.allWpProjectType}
                              filterGroup="type" />
                          </div>

                          <div className="uk-margin">
                            <FilterRadio
                              filterName="Sector"
                              filterData={data.allWpSector}
                              filterGroup="sector" />
                          </div>

                          <div className="uk-margin">
                            <FilterCheckbox
                              filterName="Technologies"
                              filterData={data.allWpProjectTechnology}
                              filterGroup="technologies" />
                          </div>

                          <div className="uk-margin">
                            <FilterSelect
                              filterName="Date"
                              filterData={data.allWpProjectYear}
                              filterGroup="year" />
                          </div>
                        </div>
                        {/* End .filters */}

                      </div> {/* End .uk-width-expand */}
                    </div> {/* End .uk-grid-small.uk-flex-middle */}

                  </div> {/* End .uk-offcanvas-bar */}
                </div> {/* End #offcanvas-push */}

                <div className="uk-hidden js-filter-wrapper">
                  <FilterSearchWork allWork={dataToDisplay} />
                  {/* <Img
                    title="Header image"
                    alt="Greek food laid out on table"
                    sizes={this.props.data.headerImage.sizes}
                  /> */}
                  {data != undefined ? (
                    <div className="js-work-list work-list">
                      {workList}
                    </div>
                  ) : (
                      <p>There is no work yet.</p>
                    )}
                </div> {/* End data-uk-filter */}

              </div> {/* End .ctn-main.uk-padding */}


            </div> {/* End .uk-offcanvas-content.uk-background-muted */}
          </div>
          {/* End .work-template */}
        </Layout>
      );
    } else {
      return (
        <></>
      )
    }
  }
}

export default Work;

export const pageQuery = graphql`
  query {
    wpPage(slug: {eq: "work"}) {
      id
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                sizes: "(min-width: 1429px) 1920px, 100vw"
              )
            }
          }
          sourceUrl
        }
      }
      titleSubtitleACF {
        subtitlePage
        titlePage
      }
    }
    allWpProject(sort: {fields: date}) {
      edges {
        node {
          id
          slug
          title
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                    sizes: "(min-width: 1429px) 1920px, 100vw"
                  )
                }
              }
              sourceUrl
            }
          }
          sectors {
            nodes {
              id
              name
              slug
            }
          }
          projectYears {
            nodes {
              id
              name
              slug
            }
          }
          projectFeatures {
            nodes {
              name
              id
              slug
            }
          }
          projectTechnologies {
            nodes {
              id
              name
              slug
            }
          }
          projectTypes {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
    allWpProjectType(sort: {fields: [count], order: DESC}) {
      edges {
        node {
          id
          name
          slug
          count
        }
      }
    }
    allWpSector(sort: {fields: [count], order: DESC}) {
      edges {
        node {
          id
          name
          slug
          count
        }
      }
    }
    allWpProjectTechnology(sort: {fields: [count], order: DESC}) {
      edges {
        node {
          id
          name
          slug
          count
        }
      }
    }
    allWpProjectYear {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }

`
