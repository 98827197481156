import React, { Component } from 'react';

class FilterSelect extends Component {
  render() {

    const filterName = this.props.filterName;
    const filterData = this.props.filterData;
    const filterGroup = this.props.filterGroup

    return (
      <div>
        <h3>{filterName}: </h3>
        <div className={`filter-select-group filter-${filterName.toString().toLowerCase()}`}>
          <select className="js-filter-group uk-select uk-form-width-large uk-margin-small" data-filter-group={`${filterGroup}`}>
            <option value="" data-filter="">All</option>
            {filterData.edges.map(({ node }) => (
              <option className="filter" key={node.id} data-filter={`.filter-${node.slug}`} value={`.filter-${node.slug}`} dangerouslySetInnerHTML={{ __html: node.name }} />
              ))}
          </select>
        </div>
      </div>
    );
  }
}

export default FilterSelect;